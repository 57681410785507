import React from "react";
import { Link } from "react-router-dom";

import chevron_right from "./components/emblems/chevron_right_w.svg";
import hero from "./components/emblems/hero.png";
import { useUser } from "./context/UserProvider";
import { useNavigation } from "./context/NavigationProvider";

export const Home = () => {
  const { preferences, setPreferences } = useUser();
  const { setNodeId } = useNavigation();
  setPreferences(preferences.reset());
  setNodeId("1");
  return (
    <div className="page fade-in-section">
      <p className="title">Men read romance too. </p>

      <img src={hero} alt={"hero"}></img>
      <p className="h1">Let's find the right book for you. </p>
      <Link className="button proceed positionRelative" to={`/recommendations`}>
        Get started
        <img className="smallIcon abs far" src={chevron_right} alt={"go"} />
      </Link>
    </div>
  );
};

export default Home;
