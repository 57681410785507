import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GoogleAnalytics from "./analytics/GoogleAnalytics";
import MixpanelAnalytics from "./analytics/MixpanelAnalytics";
import { UserProvider } from "./context/UserProvider";
import { NavigationProvider } from "./context/NavigationProvider";

import NotFound from "./NotFound";
import Home from "./Home";
import Node from "./Node";

function App() {
  return (
    <Router>
        <UserProvider>
          <NavigationProvider>
            <GoogleAnalytics />
            <MixpanelAnalytics />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/recommendations" element={<Node />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </NavigationProvider>
        </UserProvider>
    </Router>
  );
}

export default App;
