import React, { createContext, useState, useContext } from "react";

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [nodeId, setNodeId] = useState("1");

  return (
    <NavigationContext.Provider value={{ nodeId, setNodeId }}>
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigation = () => {
  return useContext(NavigationContext);
};

export { NavigationProvider, useNavigation };
