import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { useNavigation } from "../context/NavigationProvider";
import chevron_right from "./emblems/chevron_right_w.svg";
import bookData from "../data/books.json";

const ResponseList = ({ node }) => {
  const [selected, setSelected] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [filter, setFilter] = useState(null);
  const { preferences, setPreferences } = useUser();
  const { setNodeId } = useNavigation();

  const handleSelect = (linkTo, filter, label) => {
    if (selected === linkTo) {
      setSelected(null);
      setSelectedLabel(null);
      setFilter(null);
    } else {
      setSelected(linkTo);
      setSelectedLabel(label);
      setFilter(filter);
    }
  };

  const updatePreferences = () => {
    if (filter !== null) {
      preferences.add_preference(filter.positive_tags, filter.negative_tags, node.id)
      setPreferences(preferences);
      setNodeId(selected);
      setSelected(null);
      setSelectedLabel(null);
      setFilter(null);
    }
  };

  const showOption = (navigation_item) => {
    // Determine if there are any books left after selecting this option
    const newPreferences = preferences.clone();
    const filter = navigation_item.filter;
    newPreferences.add_preference(filter.positive_tags, filter.negative_tags, node.id);
    const books = bookData.filter(book => newPreferences.fits_preferences(book.tags));

    if (books.length === 0) {
      return false;
    }

    // Determine if this option should only be shown if a some previous option was selected
    if (navigation_item["conditional_visibility"]) {
      console.log(navigation_item);
      return preferences.has_positive_tag(navigation_item["conditional_visibility"]);
    }
    return true;
  };

  if (node.navigation.length === 0) {
    return <p className="h2 moderateWidth centering">No more questions!</p>;
  }

  return (
    <div className="responseContainer">
      {node.navigation.filter(showOption).map((answer) => (
        <div
          className={`button input ${
            selectedLabel === answer.label ? "selected" : ""
          }`}
          onClick={() =>
            handleSelect(answer.linkTo, answer.filter, answer.label)
          }
          key={answer.label}
        >
          {answer.label}
        </div>
      ))}
      <div className="spacerS" />
      {selected ? (
        <Link
          className="button proceed positionRelative"
          onClick={updatePreferences}
        >
          Continue
          <img className="smallIcon abs far" src={chevron_right} alt={"go"} />
        </Link>
      ) : (
        <div className="button proceed disabled">Continue</div>
      )}
    </div>
  );
};

export default ResponseList;
