import React from "react";

import hat_wizard from "./emblems/hat_wizard.svg";
import rocket_launch from "./emblems/rocket_launch.svg";
import user_tie from "./emblems/user_tie.svg";
import chess_rook from "./emblems/chess_rook.svg";

const GenreIcon = ({ genre }) => {
  let imageSrc, altText;

  switch (genre) {
    case "Fantasy":
      imageSrc = hat_wizard;
      altText = "Fantasy";
      break;
    case "Science Fiction":
      imageSrc = rocket_launch;
      altText = "Sci Fi";
      break;
    case "Contemporary":
      imageSrc = user_tie;
      altText = "Contemporary";
      break;
    case "Historical":
      imageSrc = chess_rook;
      altText = "Historical";
      break;
    default:
      imageSrc = null;
      altText = "Unknown genre";
  }

  return (
    <div className="genreIcon">
      {imageSrc ? <img src={imageSrc} alt={altText} /> : null}
    </div>
  );
};

export default GenreIcon;
