import React from "react";

import west from "../emblems/west.svg";
import RFM from "./rfmLogo";

import { useUser } from "../../context/UserProvider";
import { useNavigation } from "../../context/NavigationProvider";


const Header = () => {
  const { setNodeId } = useNavigation();
  const { preferences, setPreferences } = useUser();
  function backClick() {
    const node_id = preferences.pop_preference();
    if (node_id !== null){ 
      setNodeId(node_id);
      setPreferences(preferences);
    }
  }
  return (
    <div className="headerContainer">
      <div className="navBars" onClick={backClick} >
        <img src={west} alt={"Nav Menu"} />
      </div>
      <div />
      <RFM />
    </div>
  );
};

export default Header;
