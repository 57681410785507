class Preference {
    constructor(positive_tags, negative_tags, node_id) {
        this.positive_tags = new Set(positive_tags);
        this.negative_tags = new Set(negative_tags);
        this.node_id = node_id;
    }

    fits_preference(tags) {
        if (this.negative_tags.size === 0 && this.positive_tags.size === 0) {
            return true;
        }
        if (tags.some(tag => this.negative_tags.has(tag))) {
            return false;
        }
        if (tags.some(tag => this.positive_tags.has(tag))) {
            return true;
        }
        return false;
    }

    has_positive_tag(tag) {
        return this.positive_tags.has(tag);
    }
}

const default_hidden = ["kink:femdom", "relationship:haremlit", "relationship:poly", "relationship:mff", "story:erotic-romance", "fmc:dangerously-furry",
                        "story:pulp-hidden", "story:pulp-h", "story:subplot-romance-hidden", "story:subplot-romance-h"];

export class Preferences {
    #preferences;

    constructor() {
        this.#preferences = [];
    }

    reset() {
        this.#preferences = [];
        return this;
    }

    add_preference(positive_tags, negative_tags, node_id) {
        console.assert(typeof positive_tags !== 'undefined', "positive_tags is undefined");
        console.assert(typeof negative_tags !== 'undefined', "negative_tags is undefined");
        console.assert(typeof node_id !== 'undefined', "node_id is undefined");
        // console.log(positive_tags);
        // console.log(negative_tags);
        this.#preferences.push(new Preference(positive_tags, negative_tags, node_id))
    }

    pop_preference() {
        if (this.#preferences.length > 0) {
            return this.#preferences.pop().node_id;
        }
        return null;
    }

    get positive_tags() {
        let ret = [];
        for (let preference of this.#preferences) {
            ret.push(...preference.positive_tags)
        }
        return new Set(ret);
    }

    get negative_tags() {
        let ret = [];
        for (let preference of this.#preferences) {
            ret.push(...preference.negative_tags)
        }
        return ret;
    }

    has_positive_tag(tag) {
        return this.#preferences.some(preference => preference.has_positive_tag(tag));
    }

    fits_preferences(tags) {
        const tags_set = new Set(tags);
        const positive_tags = this.positive_tags;
        if (default_hidden.some(hidden_tag => tags_set.has(hidden_tag) && !positive_tags.has(hidden_tag))) {
            return false;
        }
        for (let preference of this.#preferences) {
            if (!preference.fits_preference(tags)) {
                return false;
            }
        }
        return true;
    }

    clone() {
        const copy = new Preferences();
        copy.#preferences = [...this.#preferences];
        return copy;
    }

}
