import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserProvider";
import { useLocation } from "react-router-dom";
import bookData from "../data/books.json";

import Book from "./Book";

const BookRecommendations = () => {
  const location = useLocation();
  const [animationKey, setAnimationKey] = useState(0);

  const { preferences } = useUser();

  let books = bookData.filter(book => preferences.fits_preferences(book.tags));
  books.sort((a, b) => a.rank - b.rank);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [location]);

  if (books.length === 0) {
    console.log("no books")
    return (
      <p className="h2 moderateWidth centering">
        No books found based on your preferences.
      </p>
    );
  }

  return (
    <div className="fade-in-section delay" key={animationKey}>
      <p className="h2 moderateWidth centering">
        {"Try one of these books!"}
      </p>
      <div className="spacerL" />
      <div className="recContainer">
        {books.map((book, index) => (
          <Book key={book.id} book={book} index={index} />
        ))}
      </div>
    </div>
  );
};

export default BookRecommendations;
