import React, { createContext, useState, useContext } from "react";
import { Preferences } from "../Preference";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(new Preferences());
  // console.log(preferences);

  return (
    <UserContext.Provider value={{ preferences, setPreferences }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  return useContext(UserContext);
};

export { UserProvider, useUser };
