import React from "react";

import headphones from "./emblems/headphones.svg";
import headset_off from "./emblems/headset_off.svg";

const AudiobookIcon = ({ audiobook }) => {
  return (
    <div className="audiobookIcon">
      {audiobook ? (
        <img src={headphones} alt="Headphones" />
      ) : (
        <img src={headset_off} alt="Headset Off" />
      )}
    </div>
  );
};

export default AudiobookIcon;
