import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import RFM from "./rfmLogo";
import chevron_right from "../emblems/chevron_right.svg";

const Footer = () => {
  const location = useLocation();
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [location]);

  return (
    <div className="fade-in-section delay" key={animationKey}>
      <div className="footerContainer">
        <p className="body centeredText">
          Join our growing community to get personal recommendations and talk
          about your favorite books.
        </p>
        <div className="footerSub">
          <RFM />
          <a
            className="noDeco"
            href="https://linktr.ee/romanceformen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="button accent">
              Romance for Men
              <img className="smallIcon" src={chevron_right} alt={"go"} />
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
