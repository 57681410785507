import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import mixpanel from "./mixpanel";

const MixpanelAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    mixpanel.track("Page View", {
      "Page URL": page,
    });

    mixpanel.time_event("Page Time Spent");

    // This function will be called when the page is about to unload
    const handleBeforeUnload = () => {
      mixpanel.track("Page Time Spent", {
        "Page URL": page,
      });
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      mixpanel.track("Page Time Spent", {
        "Page URL": page,
      });

      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname, location.search]);

  return null;
};

export default MixpanelAnalytics;
