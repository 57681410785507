import React from "react";
import { Link } from "react-router-dom";

import GenreIcon from "./GenreIcon";
import AudiobookIcon from "./AudiobookIcon";
import chevron_right from "./emblems/chevron_right_w.svg";

const Book = ({ book, index }) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="recItemContainer">
      <img
        className={`coverImage ${index % 2 === 0 ? "order1" : "order2"}`}
        src={book.cover_url}
        alt={book.title}
      />
      <div className={`bookFacts ${index % 2 === 0 ? "order2" : "order1"}`}>
        <p className="h3">{book.title}</p>{" "}
        <p className="label">By {book.author}</p>
        <div className="bookRecMetadata">
          <div className="genreContainer">
            <GenreIcon genre={book.genre} />
            <p className="label">{book.genre}</p>
          </div>
          <AudiobookIcon audiobook={book.links.Audiobook} />
        </div>
        <p className="body truncatedText">
          {truncateText(book.description, 125)}
        </p>
        <Link className="button outline positionRelative" to={book.links.Amazon} target="_blank">
          Get it now
          <img className="smallIcon abs" src={chevron_right} alt={"go"} />
        </Link>
      </div>
    </div>
  );
};

export default Book;
