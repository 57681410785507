import React, { useEffect, useState } from "react";
import BasePage from "./components/layout/BasePage";
import { useNavigation } from "./context/NavigationProvider";
import BookRecommendations from "./components/BookRecommendations";
import ResponseList from "./components/ResponseList";
import nodes from "./data/questions.json";

const Node = () => {
  const [node, setNode] = useState(null);
  const { nodeId } = useNavigation();

  useEffect(() => {
    const currentNode = nodes.find((n) => n.id.toString() === nodeId);
    setNode(currentNode);
  }, [nodeId, nodes]);

  if (!node) return null;

  return (
    <BasePage>
      <p className="h1 moderateWidth centering">{node.question}</p>
      <ResponseList node={node} />
      <div className="spacerLL" />
      {node.id !== "1" && <BookRecommendations />}
    </BasePage>
  );
};

export default Node;
