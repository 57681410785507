import React from "react";

import rfm from "../emblems/rfm_sm.webp";

const RFM = () => {
  return (
    <a
      href="https://linktr.ee/romanceformen"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="RFM" src={rfm} alt={"RFM logo"} />
    </a>
  );
};

export default RFM;
